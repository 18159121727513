import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { otpUser, otpVerify } from "../api/auth";
import { useMutation } from "react-query";
import { setUserName, setUserToken } from "../contexts/auth";
import { logout } from "../data/authEffects";

export default function OTPSignIn() {
  const navigate = useNavigate();
  const [otpSent, setOtpSent] = useState(false);
  const [userPhone, setUserPhone] = useState("");
  const [otpCode, setOtpCode] = useState("");

  const otpMutation = useMutation({
    mutationFn: otpUser,
    onSuccess: (data) => {
      setOtpSent(true);
      console.log("Sending OTP", data);
    },
    onError: (error) => {
      console.log("ERROR:", error);
    },
  });

  const otpVerifyMutation = useMutation({
    mutationFn: otpVerify,
    onSuccess: (data) => {
      if ("token" in data) {
        setUserToken(data.token);
        setUserName(data.name);
        navigate("/");
        window.location.reload();
      } else {
        // TODO toast error
        console.log("Failed to login");
        logout();
      }
    },
    onError: (error) => {
      console.log("ERROR:", error);
    },
  });

  // if (formPhone?.length > 0) {
  //   setUserPhone(formPhone);
  //   otpMutation.mutate({ phone: formPhone });
  // } else {
  //   const otp: string = data.get("otp") as string;
  //   otpVerifyMutation.mutate({ phone: userPhone, otp: otp });
  // }

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(/media/hand.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box sx={{ mt: 1 }}>
            <Box sx={{ display: otpSent ? "none" : "inherit" }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="phone"
                label="Phone Number"
                name="phone"
                autoComplete="phone"
                type="phone"
                onChange={(e) => {
                  setUserPhone(e.target.value);
                }}
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
                onClick={(e) => {
                  otpMutation.mutate({ phone: userPhone });
                }}
              >
                Send One Time Password
              </Button>
            </Box>
            <Box sx={{ display: otpSent ? "inherit" : "none" }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="otp"
                label="One Time Passcode"
                name="otp"
                type="number"
                onChange={(e) => {
                  setOtpCode(e.target.value);
                }}
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 1 }}
                onClick={() => {
                  console.log("verify otp");
                  otpVerifyMutation.mutate({ phone: userPhone, otp: otpCode });
                }}
              >
                Verify One Time Code
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 0, mb: 2 }}
                onClick={() => {
                  console.log("resend otp");
                  otpMutation.mutate({ phone: userPhone });
                }}
              >
                Resend One Time Code
              </Button>
            </Box>

            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Enter your phone number and we will send you a one time
                  password to sign in. Normal SMS rates will apply.
                </Typography>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

import React from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TournamentType, TrickType } from "../data/types";

type StandingsType = {
  name: string;
  score: number;
  loners: number;
  euchres: number;
  average: number;
  round_points: number;
  rounds: Set<number>;
  tricks: Set<number>;
};

type StandingsProps = {
  tricks: TrickType[];
  tournament: TournamentType;
  players: Map<number, string>;
};

const Standings = ({ tricks, tournament, players }: StandingsProps) => {
  // console.log("tricks", tricks);
  // console.log("tournament", tournament);
  // console.log("players", players);

  const rounds_to_players = new Map<number, number[]>();
  tournament.rounds.forEach((round) => {
    rounds_to_players.set(round.id, [
      round.north_player,
      round.south_player,
      round.east_player,
      round.west_player,
    ]);
  });
  const playerInfo = new Map<number, StandingsType>();
  players.forEach((name, id) => {
    playerInfo.set(id, {
      name,
      score: 0,
      loners: 0,
      euchres: 0,
      average: 0,
      round_points: 0,
      tricks: new Set<number>(),
      rounds: new Set<number>(),
    });
  });
  tricks.every((trick) => {
    const player = playerInfo.get(trick.player_id);
    if (!player) return true;

    player.score += trick.points;
    rounds_to_players.get(trick.round_id)?.forEach((player_id) => {
      const round_player = playerInfo.get(player_id);
      round_player?.rounds.add(trick.round_id);
      round_player?.tricks.add(trick.trick_id);
    });

    if (trick.loner) player.loners += 1;
    if (trick.euchre) player.euchres += 1;
    return true;
  });

  const standings = Array.from(playerInfo.values()).sort(
    (a, b) => b.score - a.score,
  );

  return (
    <Box sx={{ flexGrow: 1, padding: "1em" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Player</TableCell>
                  <TableCell>Score</TableCell>
                  <TableCell>Loners</TableCell>
                  <TableCell>Euchres</TableCell>
                  <TableCell>Ave. Score</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {standings.map((player, i) => (
                  <TableRow key={i}>
                    <TableCell>{player.name}</TableCell>
                    <TableCell>{player.score}</TableCell>
                    <TableCell>{player.loners}</TableCell>
                    <TableCell>{player.euchres}</TableCell>
                    <TableCell>
                      {player.tricks.size === 0
                        ? "-"
                        : ((player.score / player.tricks.size) * 8).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Standings;

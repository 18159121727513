
type PlayerInfoType = {
  id: number;
  token: string;
  name: string;
};

export const tournament = {
  value: {
    name: "",
    players: [] as PlayerInfoType[],
    rounds: [],
    started: false,
    complete: false,
    lockedRounds: 0,
    message: "",
  },
};

export const fetchTournament = (token: string) => {};

type StandingType = {
  name: string;
  score: number;
  loners: number;
  euchres: number;
};

export const standings = {
  value: [] as StandingType[],
};

export const playerNames = { value: new Map<number, string>() };

import {
  setPlayerToken,
  setTournamentToken,
  setUserName,
  setUserToken,
  userToken,
} from "../contexts/auth";

export const isLoggedIn = () => {
  return userToken().length > 0;
};

export const logout = () => {
  setUserToken("");
  setUserName("Guest");
  setPlayerToken("");
  setTournamentToken("");
  window.location.reload();
};

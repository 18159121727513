import { tournamentToken } from "../contexts/auth";

export const getTricks = async () => {
  const result = await fetch(`/api/tournament/${tournamentToken()}/tricks`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return result.json();
};

import { PropsWithChildren, useState } from "react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { GlobalStyles, Grid } from "@mui/material";

const drawerBleeding = 22;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

type SwipeableEdgeDrawerProps = {
  drawerText: string;
};

export default function SwipeableEdgeDrawer({
  drawerText,
  children,
}: PropsWithChildren<SwipeableEdgeDrawerProps>) {
  const [open, setOpen] = useState(false);

  return (
    <Root>
      <GlobalStyles
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `90%`,
            overflow: "visible",
          },
        }}
      />

      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        swipeAreaWidth={drawerBleeding}
        allowSwipeInChildren={true}
        disableSwipeToOpen={false}
        hysteresis={0.1}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
            backgroundColor: grey[800],
            height: drawerBleeding + 1,
          }}
          onClick={() => setOpen(!open)}
        >
          <Puller onClick={() => console.log("Click")} />
          <Grid container justifyContent="flex-end">
            <Typography
              variant="caption"
              sx={{
                pt: 0.25,
                pr: 1.5,
                color: "text.secondary",
              }}
            >
              {drawerText}
            </Typography>
          </Grid>
        </Box>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
          justifyContent={"left"}
        >
          {children}
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

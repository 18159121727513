// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/bar
import { ResponsiveBar } from "@nivo/bar";
import { SimplePlayerType } from "../data/types";
import { PlayerScoreType } from "./StandingOverview";

type BarGraphProps = {
  players: Map<number, SimplePlayerType>;
  scores: PlayerScoreType[];
  longestSeries: number;
};

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const BarChart = ({ players, scores, longestSeries }: BarGraphProps) => {
  //   console.log("BarChart");
  //   console.log("Players", players);
  //   console.log("Scores", scores);
  //   console.log("LongestSeries", longestSeries);

  const data = scores
    .map((score) => {
      return {
        player: players.get(score.player_id)?.name ?? "",
        score: score.currentScore,
      };
    })
    .sort((a, b) => a.score - b.score);

  return (
    <ResponsiveBar
      data={data}
      keys={["score"]}
      indexBy="player"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      layout="horizontal"
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "dark2" }}
      colorBy="indexValue"
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      label="indexValue"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Points",
        legendPosition: "middle",
        legendOffset: 32,
        truncateTickAt: 0,
      }}
      axisLeft={null}
      labelSkipWidth={12}
      labelSkipHeight={12}
      role="application"
      ariaLabel="Players and their scores"
      barAriaLabel={(e) =>
        e.id + ": " + e.formattedValue + " in country: " + e.indexValue
      }
    />
  );
};

import { ToggleButtonGroup, Typography, useTheme } from "@mui/material";

import MuiToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/material/styles";

type SuitSelectorProps = {
  suit: string;
  setSuit: (suit: string) => void;
};

export const SuitSelector = ({ suit, setSuit }: SuitSelectorProps) => {
  const minWidth = 65;
  const theme = useTheme();

  const ToggleButton = styled(MuiToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: theme.palette.primary.main,
    },
  });

  return (
    <ToggleButtonGroup
      value={suit}
      onChange={(event, newSuit) => {
        setSuit(newSuit);
      }}
      fullWidth
      exclusive
      // aria-label="text alignment"
      aria-label="outlined primary button group"
    >
      <ToggleButton value="spades">
        <Typography minWidth={minWidth} fontSize={32}>
          ♠
        </Typography>
      </ToggleButton>
      <ToggleButton value="diamonds">
        <Typography minWidth={minWidth} fontSize={32}>
          ♦
        </Typography>
      </ToggleButton>
      <ToggleButton value="clubs">
        <Typography minWidth={minWidth} fontSize={32}>
          ♣
        </Typography>
      </ToggleButton>
      <ToggleButton value="hearts">
        <Typography minWidth={minWidth} fontSize={32}>
          ♥
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

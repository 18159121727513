// Local storage keys
const LOCAL_STORAGE_TOURNAMENT_TOKEN = "tournamentToken";
const LOCAL_STORAGE_USER_TOKEN = "userToken";
const LOCAL_STORAGE_USER_NAME = "userName";
const LOCAL_STORAGE_PLAYER_TOKEN = "playerToken";

const readToken = (key: string) => {
  const token = localStorage.getItem(key) || "";
  return token;
};

export const userToken = (): string => readToken(LOCAL_STORAGE_USER_TOKEN);
export const userName = (): string => readToken(LOCAL_STORAGE_USER_NAME);
export const tournamentToken = () => readToken(LOCAL_STORAGE_TOURNAMENT_TOKEN);
export const playerToken = () => readToken(LOCAL_STORAGE_PLAYER_TOKEN);

export const setUserToken = (token: string) => {
  localStorage.setItem(LOCAL_STORAGE_USER_TOKEN, token);
};

export const setUserName = (name: string) => {
  localStorage.setItem(LOCAL_STORAGE_USER_NAME, name);
};

export const setTournamentToken = (token: string) => {
  localStorage.setItem(LOCAL_STORAGE_TOURNAMENT_TOKEN, token);
};

export const setPlayerToken = (token: string) => {
  localStorage.setItem(LOCAL_STORAGE_PLAYER_TOKEN, token);
};

import { useState } from "react";
import { useMutation } from "react-query";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { queryClient } from "../../api/query";
import { grey } from "@mui/material/colors";
import { Link } from "react-router-dom";
import { PlayerRoundType, RoundType, TournamentType } from "../../data/types";
import { suitLookup } from "../TableScore";
import { DealerDialog } from "../../components/DealerDialog";
import { tournamentToken } from "../../contexts/auth";

type DealersProps = {
  tournament: TournamentType;
  players: Map<number, string>;
  round: PlayerRoundType;
  roundTricks: any;
  trickCount: number;
  currentRound: number;
  currentTable: number;
};

export const setDealers = async ({
  dealer1,
  dealer2,
  round,
}: {
  round: number;
  dealer1: number;
  dealer2: number;
}) => {
  const body = JSON.stringify({
    dealer1,
    dealer2,
  });

  const result = await fetch(
    `/api/tournament/${tournamentToken()}/round/${round}/dealers`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    },
  );

  return result.json();
};

export const Dealers = ({
  tournament,
  players,
  round,
  roundTricks,
  trickCount,
  currentRound,
  currentTable,
}: DealersProps) => {
  const [dealerDialog, setDealerDialog] = useState<boolean>(false);
  const [secondDealerDialog, setSecondDealerDialog] = useState<boolean>(false);
  const [dealerIndex, setDealerIndex] = useState<number>(0);

  const tround: RoundType | undefined = tournament.rounds.find(
    (r) => r.round === currentRound && r.tablenum === currentTable,
  );
  const dealer1 = tround?.dealer1 ?? 0;
  const dealer2 = tround?.dealer2 ?? 2;

  const dealerMap = [dealer1, dealer2, dealer1 ^ 1, dealer2 ^ 1];
  const playerArray = [
    tround?.north_player ?? 0,
    tround?.south_player ?? 0,
    tround?.east_player ?? 0,
    tround?.west_player ?? 0,
  ];

  const dealerName = (round: number) => {
    const index = playerArray[dealerMap[round % 4]];
    const player = players.get(index);
    return player ?? "";
  };

  const dealerCallback = (index: number) => {
    setDealerDialog(false);
    if (index === -1) return;
    setDealerIndex(index);
    setSecondDealerDialog(true);
  };

  const dealerMutation = useMutation({
    mutationFn: setDealers,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["tournament", tournamentToken()]);
    },
    onError: () => {},
  });

  const saveDealers = (dealer2: number) => {
    setSecondDealerDialog(false);
    if (dealer2 === -1) return;

    let offset = dealerIndex < 2 ? 2 : 0;
    dealerMap[0] = dealerIndex;
    dealerMap[1] = dealer2 + offset;
    dealerMap[2] = dealerIndex ^ 1;
    dealerMap[3] = (dealer2 + offset) ^ 1;

    dealerMutation.mutate({
      dealer1: dealerIndex,
      dealer2: dealer2 + offset,
      round: round.round_id,
    });
  };

  return (
    <Grid item xs={12}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell component="th" width="45%">
                <Link to="#" onClick={() => setDealerDialog(true)}>
                  Dealer
                </Link>
              </TableCell>
              {/* <TableCell component="th">Dealer</TableCell> */}
              <TableCell align="left" width="20%">
                Score
              </TableCell>
              <TableCell align="right" width="35%">
                Notes
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roundTricks.map((trick: any, i: number) => (
              <TableRow key={trick.trick_id}>
                <TableCell>
                  {i + 1} - {dealerName(i)}
                </TableCell>
                <TableCell align="left">
                  {trick.our_points ? trick.points : 0} -{" "}
                  {trick.our_points ? 0 : trick.points}
                </TableCell>
                <TableCell align="right">
                  {trick.loner.length > 0 && `${trick.loner} loner`}
                  {trick.euchred.length > 0 && `${trick.euchred} euchred`}
                  {trick.suit && suitLookup[trick.suit]}
                </TableCell>
              </TableRow>
            ))}

            {trickCount < 8 &&
              (Array(8 - trickCount).fill(0) ?? []).map((_, i) => (
                <TableRow
                  key={i}
                  sx={{ backgroundColor: i === 0 ? grey[900] : "inherit" }}
                >
                  <TableCell>
                    {i + trickCount + 1} - {dealerName(i + trickCount)}
                  </TableCell>
                  <TableCell align="left">-</TableCell>
                  <TableCell align="right">-</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DealerDialog
        open={dealerDialog}
        message="Who has the first deal?"
        player_ids={[0, 1, 2, 3]}
        players={players}
        dealerMap={playerArray}
        onClose={dealerCallback}
      />
      <DealerDialog
        open={secondDealerDialog}
        message="Who has the second deal?"
        player_ids={dealerIndex < 2 ? [2, 3] : [0, 1]}
        dealerMap={playerArray}
        players={players}
        onClose={saveDealers}
      />
    </Grid>
  );
};

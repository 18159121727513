import {
  Avatar,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import PersonIcon from "@mui/icons-material/Person";
import { SlideUpTransition } from "./SlideUp";

export interface DealerDialogProps {
  open: boolean;
  message: string;
  player_ids: number[];
  dealerMap: number[];
  players: Map<number, string>;
  onClose?: (id: number) => void;
}

export function DealerDialog(props: DealerDialogProps) {
  const { onClose, open, message, player_ids, players, dealerMap } = props;

  return (
    <Dialog
      open={open}
      onClose={() => onClose?.(-1)}
      TransitionComponent={SlideUpTransition}
    >
      <DialogTitle>{message}</DialogTitle>
      <List sx={{ pt: 0 }}>
        {player_ids.map((id, i) => (
          <ListItem disableGutters key={id}>
            <ListItemButton onClick={() => onClose?.(i)}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={players.get(dealerMap[id])} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

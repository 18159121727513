import { useState } from "react";
import { Avatar, Grid, TextField, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import {
  playerToken,
  setPlayerToken,
  setTournamentToken,
  tournamentToken,
} from "../contexts/auth";
import { getTournament } from "../api/tournaments";
import { useMutation, useQuery } from "react-query";
import { PlayerType } from "../data/types";
import { queryClient } from "../api/query";
import { saveName } from "../data/player";
import EmojiPicker, { Theme } from "emoji-picker-react";

export default function Player(props: any) {
  const pToken = useParams()["playerToken"] || "";
  const tToken = useParams()["tournamentToken"] || "";
  const [isNameFocused, setIsNamedFocused] = useState(false);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

  setTournamentToken(tToken);
  setPlayerToken(pToken);

  const { isLoading, isError, data } = useQuery({
    queryKey: ["tournament", tournamentToken()],
    queryFn: () => getTournament(),
    onSuccess: (data) => {
      console.log("Tournament Success:", data);
    },
  });

  const nameMutation = useMutation({
    mutationFn: saveName,
    onSuccess: (data) => {
      console.log("Name Changed:", data);
      queryClient.invalidateQueries(["tournament", tournamentToken()]);
      queryClient.invalidateQueries(["player", playerToken()]);
    },
    onError: (error) => {
      console.log("ERROR:", error);
    },
  });

  const playerName =
    data?.players?.find((p: PlayerType) => p.token === pToken)?.name ??
    "Who are you?";
  const playerEmoji =
    data?.players?.find((p: PlayerType) => p.token === pToken)?.emoji ?? "🤷‍♂️";
  const [name, setName] = useState(playerName);
  const [emoji, setEmoji] = useState(playerEmoji);

  if (isLoading) return <h1>Loading...</h1>;
  if (isError) return <h1>Error, please refresh</h1>;

  console.log("Player:", playerName);

  const urlPath = `/welcome/${tournamentToken()}/player/${playerToken()}`;
  const url = `${process.env.REACT_APP_HOSTNAME}${urlPath}`;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Link to={urlPath}>
          <QRCode value={url} />
        </Link>
      </Grid>
      <Grid item xs={12}>
        {!isNameFocused ? (
          <Typography
            className="name"
            variant="h3"
            onClick={() => {
              setIsNamedFocused(true);
              setName(playerName);
              setEmoji(playerEmoji);
            }}
          >
            {playerName}
          </Typography>
        ) : (
          <TextField
            autoFocus
            inputProps={{
              style: {
                fontWeight: 400,
                fontSize: "3rem",
                lineHeight: "0px",
                padding: "0px",
              },
            }}
            value={name}
            onChange={(event) => setName(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                setIsNamedFocused(false);
                nameMutation.mutate({ name, emoji });
              }
            }}
            onBlur={(event) => {
              console.log("onBlur", event);
              nameMutation.mutate({ name, emoji });
              setIsNamedFocused(false);
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} paddingLeft={3}>
        <Typography variant="h4" onClick={() => setIsEmojiPickerOpen(true)}>
          <Avatar
            alt="🤖"
            sx={{ fontSize: "60px", width: "80px", height: "80px" }}
          >
            {emoji}
          </Avatar>
        </Typography>
      </Grid>
      {isEmojiPickerOpen && (
        <Grid item xs={12} paddingTop={5}>
          <EmojiPicker
            onEmojiClick={(emoji) => {
              nameMutation.mutate({ name, emoji: emoji.emoji });
              console.log("emoji", emoji);
              setEmoji(emoji.emoji);
              return setIsEmojiPickerOpen(false);
            }}
            searchDisabled={true}
            theme={Theme.DARK}
          />
          ;
        </Grid>
      )}
    </Grid>
  );
}

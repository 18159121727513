import { useEffect, useState } from "react";
import { setTournamentToken, tournamentToken } from "../contexts/auth";
import { useQuery } from "react-query";
import { getTournament } from "../api/tournaments";
import { getTricks } from "../api/tricks";
import { useNavigate, useParams } from "react-router-dom";
import { RoundType, SimplePlayerType, TrickType } from "../data/types";
import { Box, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import StandingsGrid from "./StrandingsGrid";
import TournamentGraph from "./TournamentGraph";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { BarChart } from "./BarChart";

// type definition of playerScores:
export type PlayerScoreType = {
  player_id: number;
  rounds: number[];
  scores: number[];
  runningScores: number[];
  currentScore: number;
};

const groupByToRecord = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);

const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  Object.entries(groupByToRecord(arr, key)).map(([k, v]) => v);

export default function StandingOverview() {
  const navigate = useNavigate();
  const token =
    useParams<{ tournamentToken: string }>()["tournamentToken"] || "";

  if (token.length > 0 && tournamentToken() !== token) {
    setTournamentToken(token);
  }

  const {
    isLoading,
    isError,
    data: tournament,
  } = useQuery({
    queryKey: ["tournament", tournamentToken()],
    queryFn: () => getTournament(),
    onError: (error) => {
      navigate("/signin");
    },
  });

  const {
    isLoading: isLoadingTricks,
    isError: isErrorTricks,
    data: tricks,
  } = useQuery({
    queryKey: ["tricks", tournamentToken()],
    queryFn: () => getTricks(),
    // refetchInterval: 1000,
  });

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isLoading || isLoadingTricks) return <h1>Loading...</h1>;
  if (isError || isErrorTricks) return <h1>Error, please refresh</h1>;

  const players = tournament.players.reduce((acc: any, player: any) => {
    acc.set(player.id, player);
    return acc;
  }, new Map<number, any>());

  const playerRounds = tournament.players.map((p: SimplePlayerType) => {
    return {
      player_id: p.id,
      rounds: tournament.rounds
        .filter(
          (r: RoundType) =>
            r.north_player === p.id ||
            r.south_player === p.id ||
            r.east_player === p.id ||
            r.west_player === p.id,
        )
        .map((r: RoundType) => r.id)
        .sort(),
    };
  });

  const preparedTricks = (
    groupBy(tricks.tricks, (t: TrickType) => t.trick_id) as TrickType[][]
  )
    .map((t: TrickType[]) => {
      return {
        trick_id: t[0].trick_id,
        round_id: t[0].round_id,
        points: t.reduce((acc, val) => Math.max(acc, val.points), 0),
        players: t.reduce((acc, val) => {
          if (val.points > 0) acc.push(val.player_id);
          return acc;
        }, [] as number[]),
      };
    })
    .sort((a, b) => a.round_id - b.round_id || a.trick_id - b.trick_id);

  const playerScores = playerRounds
    .map((pr: any) => {
      const scores = preparedTricks
        .filter((t: any) => pr.rounds.includes(t.round_id))
        .map((t: any) => (t.players.includes(pr.player_id) ? t.points : 0));
      const runningScores = scores.reduce(
        (acc: number[], val: number) => {
          acc.push((acc[acc.length - 1] ?? 0) + val);
          return acc;
        },
        [0],
      );

      return {
        player_id: pr.player_id,
        rounds: pr.rounds,
        scores: scores,
        runningScores: runningScores,
        currentScore:
          runningScores.length > 0
            ? runningScores[runningScores.length - 1]
            : 0,
      };
    })
    .sort((a: any, b: any) => b.currentScore - a.currentScore);

  const series = playerScores.map((p: any) => {
    return {
      data: p.runningScores,
      id: players.get(p.player_id).name,
      label: players.get(p.player_id).name,
      showMark: ({ index }: { index: number }) => index % 8 === 0,
      type: "line",
    };
  });

  const longestSeries = series.reduce((acc: number, val: any) => {
    return Math.max(acc, val.data.length);
  }, 0) as number;

  return longestSeries < 1 ? (
    <Typography>Waiting for scores...</Typography>
  ) : (
    <Box p={5}>
      <Swiper
        loop={true}
        navigation={false}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Navigation]}
        on={{
          init: (e: any) => {
            e.params.speed = 1500;
          },
        }}
      >
        <SwiperSlide>
          <TournamentGraph
            windowDimensions={windowDimensions}
            scores={playerScores}
            players={players}
            longestSeries={longestSeries}
          />
        </SwiperSlide>
        <SwiperSlide>
          <StandingsGrid scores={playerScores} players={players} />
        </SwiperSlide>
        <SwiperSlide>
          <Box height={windowDimensions.height * 0.9 - 100}>
            <BarChart
              scores={playerScores}
              players={players}
              longestSeries={longestSeries}
            />
          </Box>
        </SwiperSlide>
      </Swiper>
    </Box>
  );
}

import { LineChart } from "@mui/x-charts/LineChart";
import { ChartsXAxis, ChartsYAxis, LineSeriesType } from "@mui/x-charts";
import { SimplePlayerType } from "../data/types";
import { PlayerScoreType } from "./StandingOverview";

type TournamentGraphProps = {
  players: Map<number, SimplePlayerType>;
  scores: PlayerScoreType[];
  longestSeries: number;
  windowDimensions: { width: number; height: number };
};

export default function TournamentGraph({
  players,
  scores: playerScores,
  longestSeries,
  windowDimensions,
}: TournamentGraphProps) {
  const series: LineSeriesType[] = playerScores.map((p) => {
    return {
      data: p.runningScores,
      id: players.get(p.player_id)?.name ?? "",
      label: players.get(p.player_id)?.name ?? "",
      showMark: ({ index }: { index: number }) => index % 8 === 0,
      type: "line",
    };
  });

  series.push({
    data: Array.from(Array(longestSeries)).map((_, i) => i * (7.0 / 8.0)),
    label: "Win Line",
    showMark: false,
    type: "line",
  } as LineSeriesType);

  const rounds = Array.from(Array(Math.ceil(longestSeries / 8))).map(
    (_, i) => i + 1,
  );
  const ticks = Array.from(Array(longestSeries)).map((_, i) => (1.0 / 8.0) * i);

  return (
    <LineChart
      height={windowDimensions.height * 0.9 - 100}
      series={series}
      xAxis={[
        {
          tickMinStep: 1,
          tickMaxStep: 1,
          data: ticks,
        },
      ]}
    >
      <ChartsXAxis label="Rounds" tickInterval={rounds} />
      <ChartsYAxis label="Points" />
    </LineChart>
  );
}

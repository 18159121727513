export const loginUser = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  return fetch("/api/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  }).then((data) => data.json());
};

export const otpUser = async ({ phone }: { phone: string }) => {
  return fetch("/api/send/otp", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ phone }),
  }).then((data) => data.json());
};

export const otpVerify = async ({
  phone,
  otp,
}: {
  phone: string;
  otp: string;
}) => {
  return fetch("/api/verify/otp", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ phone, otp }),
  }).then((data) => data.json());
};

export const registerUser = async ({
  name,
  phone,
}: {
  name: string;
  phone: string;
}) => {
  const data = await fetch("/api/register", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name, phone }),
  });
  return await data.json();
};

import { TrickType } from "./types";

export type TrickInfoType = {
  loners: number;
  euchres: number;
  score: number;
  tricks: number;
};

export const trickStore = { value: [] };

export const updateTricks = () => {};

export const playerRoundInfo = (
  playerId: number,
  roundId: number,
): TrickInfoType => {
  return {
    loners: 0,
    euchres: 0,
    score: 0,
    tricks: 0,
  };
};

export const playerTourneyInfo = (playerId: number): TrickInfoType => {
  return {
    loners: 0,
    euchres: 0,
    score: 0,
    tricks: 0,
  };
};

export const tricksCountForRound = (round_id: number) => {
  return 0;
};

export const tableTricks = { value: 0 };

export const trickCount = { value: 0 };

export const score = (
  points: number,
  type: string,
  hand_player?: number,
  onSuccess?: () => void,
) => {};

export const undo = (onSuccess?: () => void) => {};

type TrickSummary = {
  trick_id: number;
  winners: string[];
  euchred: string;
  loner: string;
  points: number;
  our_points: boolean;
  us: number;
  them: number;
  suit: string;
};

export const calcRoundInfo = (
  tricks: TrickType[],
  playerId: number,
  roundId: number,
): TrickInfoType => {
  const trickCount = new Set<number>();
  const roundTricks = tricks.filter((t) => t.round_id === roundId);
  roundTricks.every((t) => {
    trickCount.add(t.trick_id);
    return true;
  });
  const result = roundTricks
    .filter((t) => t.player_id === playerId)
    .reduce(
      (acc: TrickInfoType, trick: TrickType) => {
        return {
          loners: acc.loners + (trick.loner ? 1 : 0),
          euchres: acc.euchres + (trick.euchre ? 1 : 0),
          score: acc.score + trick.points,
          tricks: 0,
        };
      },
      {
        loners: 0,
        euchres: 0,
        score: 0,
        tricks: roundTricks.length,
      },
    );
  result.tricks = trickCount.size;
  return result;
};

export const tricksForRound = (
  me: number,
  tricks: TrickType[],
  players: Map<number, string>,
  round_id: number,
) => {
  // filter out duplicate tricks based on trick_id
  const uniqueTricks = new Map<number, TrickSummary>();

  tricks
    .filter((trick) => trick.round_id === round_id)
    .every((trick) => {
      const currentTrick = uniqueTricks.get(trick.trick_id);
      if (currentTrick) {
        if (trick.loner) {
          currentTrick.loner = players.get(trick.player_id) ?? "";
          currentTrick.winners.push(players.get(trick.player_id) ?? "");
        } else if (trick.euchre) {
          currentTrick.euchred = players.get(trick.player_id) ?? "";
        } else {
          currentTrick.winners.push(players.get(trick.player_id) ?? "");
        }
        currentTrick.points = Math.max(trick.points, currentTrick.points);
        currentTrick.suit = trick.suit;
        currentTrick.our_points =
          currentTrick.our_points ||
          (trick.player_id === me && trick.points > 0);
      } else {
        uniqueTricks.set(trick.trick_id, {
          trick_id: trick.trick_id,
          euchred: (trick.euchre && players.get(trick.player_id)) || "",
          loner: (trick.loner && players.get(trick.player_id)) || "",
          points: trick.points,
          winners: trick.euchre ? [] : [players.get(trick.player_id) || ""],
          our_points: trick.player_id === me && trick.points > 0,
          us: 0,
          them: 0,
          suit: "",
        });
      }

      return true;
    });

  let us = 0;
  let them = 0;

  return Array.from(uniqueTricks.values())
    .sort((a, b) => a.trick_id - b.trick_id)
    .map((trick) => {
      if (trick.our_points) {
        us += trick.points;
      } else {
        them += trick.points;
      }
      trick.us = us;
      trick.them = them;
      return trick;
    });
};

import { useState } from "react";
import { Button, Grid, Typography, Avatar, Box } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import { useTheme } from "@mui/material/styles";
import { SuitSelector } from "../components/SuitSelector";
import { Link } from "react-router-dom";
import {
  PlayerRoundType,
  PlayerType,
  TournamentType,
  TrickType,
} from "../data/types";
import { calcRoundInfo, tricksForRound } from "../data/tricks";
import { playerToken } from "../contexts/auth";
import { useMutation } from "react-query";
import { queryClient } from "../api/query";
import { ScoreDialog } from "../components/ScoreDialog";
import { Dealers } from "./components/Dealers";

type TableScoreProps = {
  tricks: TrickType[];
  tournament: TournamentType;
  currentRound: number;
  players: Map<number, string>;
  player: PlayerType;
  suits: any;
  swiper: any;
};

export const undoTrick = async (round: number) => {
  const result = await fetch(
    `/api/player/${playerToken()}/round/${round}/undo`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: "{}",
    },
  );
  return result.json();
};

export const scoreTrick = async ({
  points,
  type,
  round,
  hand_player,
  suit,
  scoreFor,
  trick,
}: {
  points: number;
  type?: string;
  round: number;
  hand_player?: number;
  suit: string;
  scoreFor?: number;
  trick: number;
}) => {
  const body = JSON.stringify({
    hand: type ?? "tricks",
    points: points,
    hand_player: hand_player || -1,
    suit: suit === "" ? null : suit,
    score_for: scoreFor,
  });

  const result = await fetch(
    `/api/player/${playerToken()}/round/${round}/score`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    },
  );

  return result.json();
};

export const setSuit = async ({
  round,
  suit,
  trick,
}: {
  round: number;
  suit?: string;
  trick: number;
}) => {
  const body = JSON.stringify({
    suit: suit,
    trick: trick,
  });

  const result = await fetch(
    `/api/player/${playerToken()}/round/${round}/suit`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    },
  );

  return result.json();
};

export const suitLookup: any = {
  spades: "♠",
  diamonds: "♦",
  clubs: "♣",
  hearts: "♥",
};

function TableScore({
  tricks,
  tournament,
  currentRound,
  players,
  player,
  suits,
  swiper,
}: TableScoreProps) {
  const theme = useTheme();
  const [showPrevRound, setShowPrevRound] = useState<boolean>(false);
  const [ourScoreDialog, setOurScoreDialog] = useState<boolean>(false);
  const [theirScoreDialog, setTheirScoreDialog] = useState<boolean>(false);
  const [suit, setSuitState] = useState("");

  const visibleRound =
    showPrevRound && currentRound > 0 ? currentRound - 1 : currentRound;
  const currentTable = player.rounds[visibleRound].tablenum;
  const round: PlayerRoundType =
    player.rounds.find((r) => r.round === visibleRound) ?? player.rounds[0];

  const tInfo = {
    table: round.tablenum,
    partnerName: players.get(round.partner_id) ?? "",
    player: calcRoundInfo(tricks, player.player_id, round.round_id),
    partner: calcRoundInfo(tricks, round.partner_id, round.round_id),
    partnerId: round.partner_id,
    opponent1Id: round.opponent1_id,
    opponent2Id: round.opponent2_id,
    oppenent1Name: players.get(round.opponent1_id) ?? "",
    oppenent2Name: players.get(round.opponent2_id) ?? "",
  };

  const trickSet = new Set<number>();
  tricks
    .filter((t) => t.round_id === round.round_id)
    .every((t) => trickSet.add(t.trick_id));
  const trickCount = trickSet.size;

  const dbSuit = suits?.[round?.round_id]?.[trickCount];
  if (suit !== dbSuit && (dbSuit || suit !== "")) {
    setSuitState(dbSuit ?? "");
  }

  const scoreMutation = useMutation({
    mutationFn: scoreTrick,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["x"]);
      if (trickCount >= 7) {
        setShowPrevRound(true);
      }

      setOurScoreDialog(false);
      setTheirScoreDialog(false);
      setSuitState("");
    },
    onError: (error) => {
      console.error("ERROR:", error);
    },
  });

  const undoMutation = useMutation({
    mutationFn: undoTrick,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["tricks"]);
      setShowPrevRound(false);
    },
    onError: (error) => {
      console.error("ERROR in undo:", error);
    },
  });

  const suitMutation = useMutation({
    mutationFn: setSuit,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["tricks"]);
    },
    onError: (error) => {
      console.error("ERROR:", error);
    },
  });

  const handleSuitChange = (suit: string) => {
    setSuitState(suit);
    suitMutation.mutate({
      round: round.round_id,
      trick: trickCount,
      suit: suit,
    });
  };

  const roundTricks = tricksForRound(
    player.player_id,
    tricks,
    players,
    round.round_id,
  );

  const theirScore = roundTricks[roundTricks.length - 1]?.them ?? 0;

  return (
    <Box sx={{ flexGrow: 1, padding: "1em" }}>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <SuitSelector suit={suit} setSuit={handleSuitChange} />
        </Grid>
        <Grid item xs={9}>
          <Typography variant="h5" marginTop={1}>
            Round {visibleRound + 1}
            {showPrevRound && "*"} Table {currentTable + 1}
          </Typography>
          <Typography variant="body1">
            {player.name} {tInfo.player.loners} loners and{" "}
            {tInfo.player.euchres} euchres
          </Typography>
          <Typography variant="body1">
            {tInfo.partnerName} {tInfo.partner.loners} loners and{" "}
            {tInfo.partner.euchres} euchres
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" justifyContent="flex-end">
            {trickCount !== 0 ? (
              <Button
                onClick={() => {
                  undoMutation.mutate(visibleRound);
                }}
              >
                <Avatar
                  sx={{
                    height: 56,
                    width: 56,
                    mt: 1,
                    mb: 0,
                    bgcolor: theme.palette.primary.main,
                  }}
                >
                  <UndoIcon />
                </Avatar>
              </Button>
            ) : (
              currentRound > 0 &&
              currentRound > tournament.locked_rounds && (
                <Link to="#">
                  <Button
                    variant="contained"
                    onClick={() => {
                      setShowPrevRound(true);
                    }}
                    sx={{ height: 56, mt: 2 }}
                  >
                    Prev Round
                  </Button>
                </Link>
              )
            )}
          </Box>
        </Grid>
        {trickCount < 8 ? (
          <>
            <Grid item xs={6}>
              <Button
                variant="contained"
                fullWidth
                sx={{ minHeight: 60 }}
                onClick={() => setOurScoreDialog(true)}
              >
                Score Us
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                fullWidth
                sx={{ minHeight: 60 }}
                onClick={() => setTheirScoreDialog(true)}
              >
                Score Them
              </Button>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Link
              to="#"
              onClick={() => {
                setShowPrevRound(false);
                if (tournament.complete) {
                  swiper?.slideToLoop(2);
                }
              }}
            >
              <Button variant="contained" fullWidth sx={{ minHeight: 60 }}>
                Finish Round
              </Button>
            </Link>
          </Grid>
        )}
        <Grid item xs={6}>
          <Typography variant="h6" textAlign="center">
            {player.name} & {tInfo.partnerName}
          </Typography>
          <Typography variant="h1" textAlign="center">
            {tInfo.player.score}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" textAlign="center">
            {tInfo.oppenent1Name} & {tInfo.oppenent2Name}
          </Typography>
          <Typography variant="h1" textAlign="center">
            {theirScore}
          </Typography>
        </Grid>
        <Dealers
          tournament={tournament}
          players={players}
          round={round}
          currentRound={currentRound}
          roundTricks={roundTricks}
          trickCount={trickCount}
          currentTable={currentTable}
        />
      </Grid>
      <ScoreDialog
        open={ourScoreDialog}
        player={player.name}
        player_id={player.player_id}
        partner={tInfo.partnerName}
        partner_id={tInfo.partnerId}
        opponent1={tInfo.oppenent1Name}
        opponent1_id={tInfo.opponent1Id}
        opponent2={tInfo.oppenent2Name}
        opponent2_id={tInfo.opponent2Id}
        onClose={(points: number, type: string, hand_id: number) => {
          scoreMutation.mutate({
            points: points,
            type: type,
            round: round.round,
            hand_player: hand_id,
            suit: suit,
            trick: trickCount,
          });

          setOurScoreDialog(false);
        }}
      />
      <ScoreDialog
        open={theirScoreDialog}
        player={tInfo.oppenent1Name}
        player_id={tInfo.opponent1Id}
        partner={tInfo.oppenent2Name}
        partner_id={tInfo.opponent2Id}
        opponent1={player.name}
        opponent1_id={player.player_id}
        opponent2={tInfo.partnerName}
        opponent2_id={tInfo.partnerId}
        onClose={(points: number, type: string, hand_id: number) => {
          scoreMutation.mutate({
            points: points,
            type: type,
            round: round.round,
            hand_player: hand_id,
            suit: suit,
            trick: trickCount,
            scoreFor: tInfo.opponent1Id,
          });

          setTheirScoreDialog(false);
        }}
      />
    </Box>
  );
}

export default TableScore;

import {
  Avatar,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
import { SlideUpTransition } from "./SlideUp";
import { blue } from "@mui/material/colors";

export interface ScoreDialogProps {
  open: boolean;
  player: string;
  player_id: number;
  partner: string;
  partner_id: number;
  opponent1: string;
  opponent1_id: number;
  opponent2: string;
  opponent2_id: number;
  onClose: (points: number, type: string, player_id: number) => void;
}

export function ScoreDialog(props: ScoreDialogProps) {
  const {
    onClose,
    open,
    player,
    player_id,
    partner,
    partner_id,
    opponent1,
    opponent2,
    opponent1_id,
    opponent2_id,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={() => onClose(-1, "", -1)}
      TransitionComponent={SlideUpTransition}
    >
      <DialogTitle>
        {player} & {partner} scored
      </DialogTitle>
      <List sx={{ pt: 0 }}>
        <ListItem disableGutters>
          <ListItemButton onClick={() => onClose(1, "tricks", player_id)}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>+1</Avatar>
            </ListItemAvatar>
            3 ≤ tricks &lt; 5
          </ListItemButton>
        </ListItem>
        <ListItem disableGutters>
          <ListItemButton onClick={() => onClose(2, "tricks", player_id)}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>+2</Avatar>
            </ListItemAvatar>
            All 5 tricks
          </ListItemButton>
        </ListItem>
        <ListItem disableGutters>
          <ListItemButton onClick={() => onClose(2, "tricks", player_id)}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>+2</Avatar>
            </ListItemAvatar>
            Euchred stuck dealer
          </ListItemButton>
        </ListItem>
        <ListItem disableGutters>
          <ListItemButton onClick={() => onClose(2, "euchre", opponent1_id)}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>+2</Avatar>
            </ListItemAvatar>
            Euchred {opponent1}
          </ListItemButton>
        </ListItem>
        <ListItem disableGutters>
          <ListItemButton onClick={() => onClose(2, "euchre", opponent2_id)}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>+2</Avatar>
            </ListItemAvatar>
            Euchred {opponent2}
          </ListItemButton>
        </ListItem>
        <ListItem disableGutters>
          <ListItemButton onClick={() => onClose(4, "loner", player_id)}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>+4</Avatar>
            </ListItemAvatar>
            {player}'s Loner
          </ListItemButton>
        </ListItem>
        <ListItem disableGutters>
          <ListItemButton onClick={() => onClose(4, "loner", partner_id)}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>+4</Avatar>
            </ListItemAvatar>
            {partner}'s Loner
          </ListItemButton>
        </ListItem>
      </List>
    </Dialog>
  );
}

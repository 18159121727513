import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { PlayerType, TournamentType, TrickType } from "../data/types";
import { MyRound } from "./PlayerView";
import { grey } from "@mui/material/colors";

type PlayerScheduleProps = {
  tricks: TrickType[];
  tournament: TournamentType;
  currentRound: number;
  players: Map<number, string>;
  player: PlayerType;
  myRounds: MyRound[];
};

export default function PlayerSchedule({
  tricks,
  tournament,
  currentRound,
  myRounds,
  players,
  player,
}: PlayerScheduleProps) {
  const tournamentOver = tournament.complete;

  const currentPlayerRound = player.rounds[currentRound];
  const partnerName = players.get(currentPlayerRound.partner_id) ?? "";
  const table = currentPlayerRound.tablenum + 1;

  return (
    <Box sx={{ flexGrow: 1, padding: "1em" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">{player.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">
            {tournamentOver
              ? "You have finished the tournament. "
              : `You are playing in round ${
                  currentRound + 1
                }, with ${partnerName} at table ${table}. `}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th">Round</TableCell>
                  <TableCell component="th">Table</TableCell>
                  <TableCell align="left">Partner</TableCell>
                  <TableCell align="right">Score</TableCell>
                  <TableCell align="right">Loners</TableCell>
                  <TableCell align="right">Euchres</TableCell>
                  <TableCell align="right">Tricks Played</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {myRounds.map(({ round, info }, i) => (
                  <TableRow
                    key={players.get(round.partner_id)}
                    sx={{
                      backgroundColor:
                        currentRound === i ? grey[900] : "inherit",
                    }}
                  >
                    <TableCell>{round.round + 1}</TableCell>
                    <TableCell>{round.tablenum + 1}</TableCell>
                    <Tooltip
                      title={`Opponents ${players.get(
                        round.opponent1_id,
                      )} & ${players.get(round.opponent2_id)}`}
                    >
                      <TableCell align="left">
                        {players.get(round.partner_id)}
                      </TableCell>
                    </Tooltip>
                    <TableCell align="right">{info.score}</TableCell>
                    <TableCell align="right">{info.loners}</TableCell>
                    <TableCell align="right">{info.euchres}</TableCell>
                    <TableCell align="right">{info.tricks}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  TournamentRecord,
  deleteTournament,
  getTournaments,
  startTournament,
} from "../api/tournaments";
import { queryClient } from "../api/query";
import { logout } from "../data/authEffects";
import ConfirmDialog from "../components/ConfirmDialog";
import { useState } from "react";

export default function TourneyList() {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [tournamentToken, setTournamentToken] = useState<string>("");

  const navigate = useNavigate();
  const { isLoading, isError, data } = useQuery({
    queryKey: ["tournaments"],
    queryFn: () => getTournaments(),
  });

  const startTourneyMutation = useMutation({
    mutationFn: startTournament,
    onSuccess: (data) => {
      console.log("Success:", data);
      queryClient.invalidateQueries(["tournaments"]);
    },
    onError: (error) => {
      console.log("ERROR:", error);
    },
  });

  const deleteTourneyMutation = useMutation({
    mutationFn: deleteTournament,
    onSuccess: (data) => {
      console.log("Success:", data);
      queryClient.invalidateQueries(["tournaments"]);
    },
    onError: (error) => {
      console.log("ERROR:", error);
    },
  });

  if (isError) {
    logout();
    navigate("/signin");
    return <h1>Error, please login.</h1>;
  }

  console.log("data", data);
  return isLoading ? (
    <h1>Loading...</h1>
  ) : (
    <>
      <h1>Tournament List</h1>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell component="th">Name</TableCell>
              <TableCell align="right">Started</TableCell>
              <TableCell align="right">Completed</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.tournaments.map((row: TournamentRecord) => (
              <TableRow key={row.token}>
                <TableCell>
                  <Link
                    to={"/tournament/" + row.token}
                    onClick={() => {
                      console.log("Setting tournamentToken to", row.token);
                      setTournamentToken(row.token);
                    }}
                  >
                    {row.name}
                  </Link>
                </TableCell>
                <TableCell align="right">
                  {row.started ? (
                    "✔"
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => startTourneyMutation.mutate(row.token)}
                    >
                      Start
                    </Button>
                  )}
                </TableCell>
                <TableCell align="right">{row.completed && "✔"}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    onClick={() => {
                      setTournamentToken(row.token);
                      setConfirmDelete(true);
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog
        title="Delete Tournament?"
        open={confirmDelete}
        setOpen={setConfirmDelete}
        onConfirm={() => {
          deleteTourneyMutation.mutate(tournamentToken);
        }}
      >
        Are you sure you want to delete this tournament?
      </ConfirmDialog>
    </>
  );
}

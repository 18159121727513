import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link, Outlet } from "react-router-dom";
import { Copyright } from "./Copyright";
import { isLoggedIn, logout } from "../data/authEffects";
import { userName } from "../contexts/auth";

function Header() {
  type MenuElement = {
    label: string;
    action?: () => void;
    link?: string;
  };

  const pages: MenuElement[] = isLoggedIn()
    ? [
        { label: "Create Tournament", link: "create-tourney" },
        { label: "Tournaments", link: "tournaments" },
        { label: "About", link: "/about" },
        {
          label: "Logout",
          action: () => {
            logout();
          },
        },
      ]
    : [
        { label: "Pricing" },
        { label: "Login", link: "/signin" },
        { label: "Register", link: "/register" },
        { label: "About", link: "/about" },
      ];

  const settings: MenuElement[] = isLoggedIn()
    ? [
        { label: "Profile" },
        { label: "Account", link: "/about" },
        { label: "Dashboard" },
        {
          label: "Logout",
          action: () => {
            logout();
          },
        },
      ]
    : [
        {
          label: "Login",
          link: "/signin",
        },
        { label: "Signup", link: "/register" },
      ];

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              Euchre
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map(({ label, action, link }) =>
                  link ? (
                    <MenuItem
                      key={label}
                      component={Link}
                      to={link}
                      onClick={() => {
                        action && action();
                        handleCloseUserMenu();
                      }}
                    >
                      <Typography textAlign="center">{label}</Typography>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      key={label}
                      onClick={() => {
                        action && action();
                        handleCloseUserMenu();
                      }}
                    >
                      <Typography textAlign="center">{label}</Typography>
                    </MenuItem>
                  ),
                )}
              </Menu>
            </Box>
            <Typography variant="h4" sx={{ mr: 2 }}>
              ♠️
            </Typography>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              Euchre
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map(({ label, action, link }) =>
                link ? (
                  <Button
                    key={label}
                    component={Link}
                    to={link}
                    onClick={() => {
                      action && action();
                      handleCloseNavMenu();
                    }}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {label}
                  </Button>
                ) : (
                  <Button
                    key={label}
                    onClick={() => {
                      action && action();
                      handleCloseNavMenu();
                    }}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {label}
                  </Button>
                ),
              )}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={userName()} src={userName()} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map(({ label, action, link }) =>
                  link ? (
                    <MenuItem
                      key={label}
                      component={Link}
                      to={link}
                      onClick={() => {
                        action && action();
                        handleCloseUserMenu();
                      }}
                    >
                      <Typography textAlign="center">{label}</Typography>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      key={label}
                      onClick={() => {
                        action && action();
                        handleCloseUserMenu();
                      }}
                    >
                      <Typography textAlign="center">{label}</Typography>
                    </MenuItem>
                  ),
                )}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box sx={{ margin: 2 }}>
        <Outlet />
      </Box>
      <Copyright />
    </>
  );
}
export default Header;

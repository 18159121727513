import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { SimplePlayerType } from "../data/types";
import { PlayerScoreType } from "./StandingOverview";

type StandingsGridProps = {
  players: Map<number, SimplePlayerType>;
  scores: PlayerScoreType[];
};

export default function StandingsGrid({ players, scores }: StandingsGridProps) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Player</TableCell>
          <TableCell>Score</TableCell>
          <TableCell>Loners</TableCell>
          <TableCell>Euchres</TableCell>
          <TableCell>Ave. Score</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {scores.map((playerScore) => {
          const player = players.get(playerScore.player_id);
          if (!player) return null;

          return (
            <TableRow key={playerScore.player_id}>
              <TableCell>{player.name}</TableCell>
              <TableCell>{playerScore.currentScore}</TableCell>
              <TableCell>
                {playerScore.rounds.filter((r) => r === 2).length}
              </TableCell>
              <TableCell>
                {playerScore.rounds.filter((r) => r === 0).length}
              </TableCell>
              <TableCell>
                {(
                  (playerScore.currentScore /
                    (playerScore.runningScores.length - 1)) *
                  8.0
                ).toFixed(2)}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

import { useState } from "react";
import { Avatar, Grid, TextField, Typography } from "@mui/material";
import { useMutation } from "react-query";
import { saveName } from "../data/player";
import { queryClient } from "../api/query";
import { playerToken, tournamentToken } from "../contexts/auth";
import EmojiPicker from "emoji-picker-react";
import { Theme } from "emoji-picker-react";

type PlayerDrawerProps = {
  player: string;
  emoji: string;
};

export const PlayerDrawer = ({
  player,
  emoji: player_emoji,
}: PlayerDrawerProps) => {
  const [isNameFocused, setIsNamedFocused] = useState(false);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

  const [name, setName] = useState(player);
  const [emoji, setEmoji] = useState(player_emoji);

  const nameMutation = useMutation({
    mutationFn: saveName,
    onSuccess: (data) => {
      console.log("Name Changed:", data);
      queryClient.invalidateQueries(["tournament", tournamentToken()]);
      queryClient.invalidateQueries(["player", playerToken()]);
    },
    onError: (error) => {
      console.log("ERROR:", error);
    },
  });

  return (
    <Grid container justifyContent="flex-start">
      <Grid item xs={12}>
        <Typography variant="h4">Tap to edit your name or avator</Typography>
      </Grid>
      <Grid item xs={12} paddingTop={5}>
        <Typography variant="h4">Name:</Typography>
      </Grid>
      {!isNameFocused ? (
        <Typography
          className="name"
          variant="h3"
          onClick={() => {
            setIsNamedFocused(true);
            setName(player);
          }}
        >
          {player}
        </Typography>
      ) : (
        <TextField
          autoFocus
          inputProps={{
            style: {
              fontWeight: 400,
              fontSize: "3rem",
              lineHeight: "0px",
              padding: "0px",
            },
          }}
          value={name}
          onChange={(event) => setName(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setIsNamedFocused(false);
              nameMutation.mutate({ name, emoji });
            }
          }}
          onBlur={(event) => {
            console.log("onBlur", event);
            nameMutation.mutate({ name, emoji });
            setIsNamedFocused(false);
          }}
        />
      )}
      <Grid item xs={12} paddingTop={5}>
        <Typography variant="h4">Avatar:</Typography>
      </Grid>
      <Grid item xs={12} paddingLeft={3}>
        <Typography variant="h4" onClick={() => setIsEmojiPickerOpen(true)}>
          <Avatar
            alt="🤖"
            sx={{ fontSize: "60px", width: "80px", height: "80px" }}
          >
            {emoji}
          </Avatar>
        </Typography>
      </Grid>
      {isEmojiPickerOpen && (
        <Grid item xs={12} paddingTop={5}>
          <EmojiPicker
            onEmojiClick={(emoji) => {
              nameMutation.mutate({ name, emoji: emoji.emoji });
              setEmoji(emoji.emoji);
              return setIsEmojiPickerOpen(false);
            }}
            searchDisabled={true}
            theme={Theme.DARK}
          />
          ;
        </Grid>
      )}
    </Grid>
  );
};

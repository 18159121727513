import { playerToken } from "../contexts/auth";

export const getPlayer = async () => {
  const result = await fetch(`/api/player/${playerToken()}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return result.json();
};

import { playerToken, userToken } from "../contexts/auth";

export type PlayerRoundType = {
  round: number;
  tablenum: number;
  round_id: number;
  partner_id: number;
  opponent1_id: number;
  opponent2_id: number;
};

export const updatePlayerInfo = (token: string) => {};

export const saveName = async ({
  name,
  emoji,
}: {
  name: string;
  emoji: string;
}) => {
  const result = await fetch(`/api/player/${playerToken()}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: userToken(),
    },
    body: JSON.stringify({ name, emoji }),
  });
  return result.json();
};

import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { setPlayerToken, setTournamentToken } from "../contexts/auth";

// save the tokens and redirect to player view
export default function PlayerLanding(props: any) {
  const pToken = useParams()["playerToken"] || "";
  const tToken = useParams()["tournamentToken"] || "";
  const nav = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const goAllie = queryParams.get("allie");

  console.log("PlayerLanding: ", pToken, tToken);

  setPlayerToken(pToken);
  setTournamentToken(tToken);

  useEffect(() => {
    if (goAllie) nav("/allie");
    else nav("/player");
  });

  return (
    <div>
      <Link to={"/player"}>
        <h1>Redirecting...</h1>
      </Link>
    </div>
  );
}
